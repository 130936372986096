






















































































































































import TelaGenericaDeFaq from '@/components/layout/TelaGenericaDeFaq.vue';
import Confirmacao from '@/components/ui/Confirmacao.vue';
import { Faq, FiltroDeBuscaDeFaqs, Page, Pageable } from '@/models';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore';
import { FaqModule } from '@/store/vuex/faq/FaqStore';
import { FindFaqUseCase, FindUsuarioUseCase } from '@/usecases';
import { DeleteFaqUseCase } from '@/usecases/faq/DeleteFaqUseCase';
import axios, { CancelTokenSource } from 'axios';
import { Vue, Component, Watch, PropSync, Prop, Ref } from 'vue-property-decorator'


@Component({
	components: {
		TelaGenericaDeFaq,
		Confirmacao,
	},
})
export default class TelaDeFaq extends Vue {
@PropSync('value') selecionado!: Faq | null
@Prop({ type: String}) Usuario!: string
@Ref() confirmacao!: Confirmacao

findFaqUseCase = new FindFaqUseCase()
cancelToken: CancelTokenSource | null = null

pagina: Page<Faq> | null = null
paginado: Pageable = {
	page: 0,
	size: 20,
	sort: [],
}
carregando = false
filtro: FiltroDeBuscaDeFaqs = obterFiltroDeFaqDefault()
faqEscolhida: Faq | null = null
findUsuarioUseCase = new FindUsuarioUseCase()
deleteUseCase = new DeleteFaqUseCase()
excluindo = false
imagem = ''


async buscarPagina() {
	try {
		this.carregando = true
		if (this.cancelToken) this.cancelToken.cancel()
		this.cancelToken = axios.CancelToken.source()
		this.pagina = await this.findFaqUseCase.findFaqs({
			page: this.paginado.page || 0,
			size: this.paginado.size || 20,
			busca: this.filtro.titulo || undefined,
		},
		{
			cancelToken: this.cancelToken.token,
		})
		this.paginado.page = this.pagina.number
		FaqModule.setFaqs(this.pagina.content)
		
	} catch(e) {
		if (axios.isCancel(e)) return
		AlertModule.setError(e)
	} finally {
		this.carregando = false
	}
}

async excluirFaq() {
	if (!this.faqEscolhida) return
	try {
		this.excluindo = true
		await this.deleteUseCase.delete(this.faqEscolhida.id)
		AlertModule.setSuccess('FAQ Deletada')
	
	} catch (error: any) {
		this.selecionado = null
		AlertModule.setError(error)
	} finally {
		this.excluindo = false
		this.buscarPagina()
	}
}

async exibeConfirmacao(faq: Faq) {
	this.faqEscolhida = faq
	this.confirmacao.mostrar()
}

async created() {
	await this.buscarPagina()
	
}

async pageNext() {
	this.paginado = {
		...this.paginado,
		page: (this.paginado?.page || 0) + 1,
	}
	await this.buscarPagina()
}

async pagePrevious() {
	this.paginado = {
		...this.paginado,
		page: (this.paginado.page || 0) - 1,
	}
	await this.buscarPagina()
	
}

get usuario() {
	return UserLoginStore.usuario
}

get faqs() {
	return FaqModule.faqsOrdenadas
}

@Watch('filtro', {deep: true })
onChangeFiltro() {
	this.buscarPagina()
}

@Watch('filtro')
setFiltro() {
	this.buscarPagina()
}


}


export function obterFiltroDeFaqDefault(): FiltroDeBuscaDeFaqs {
	return {
		titulo: '',
	}
}
