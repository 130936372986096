import createSearchTags from './createSearchTags'

/**
 * Desestrutura objetos
 * @param objeto
 */
export const objectDestructuring = (object: Record<string, any>): string[] => {
	const resp = Object.values(object)
		.flatMap(item => {
			if (item instanceof Object) {
				return Object.values(item)
			}
			return item
		})
		.filter(item => item)
		.filter(value => !(value instanceof Object))
		.map(value =>
			String(value)
				.toUpperCase()
				.trim(),
		)
	return resp
}

export const search = (objects: Record<string, any>[], termo: string): Record<string, any>[] => {
	const tags = createSearchTags(termo)
	return objects
		.map(obj => ({ ...obj, tags: objectDestructuring(obj) }))
		.filter(obj =>
			tags.every(tagAPesquisar =>
				obj.tags.some(tag => tag.includes(tagAPesquisar)),
			),
		)
}
